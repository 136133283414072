<template>
  <section>
    <div class="box h-full grafica-mapa">
      <div
        id="elChart"
        ref="elChart"
        style="height:450px;"
        class="w-full"
      />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import * as echarts from 'echarts'
import { useStore } from 'vuex'
// import { setDepartment } from '../filtros/hook'
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    // debugger
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const elChart = ref(null)
    let myChart = reactive()

    const departmentsData = reactive([
        // { name: 'ANTIOQUIA', value: 4822023 },
        // { name: 'ATLANTICO', value: 731449 },
        // { name: 'SANTAFE DE BOGOTA D.C', value: 6553255 },
        // { name: 'BOLIVAR', value: 2949131 },
        // { name: 'BOYACA', value: 38041430 },
        // { name: 'CALDAS', value: 5187582 },
        // { name: 'CAQUETA', value: 3590347 },
        // { name: 'CAUCA', value: 917092 },
        { name: 'CESAR', value: 14000 },
        // { name: 'CORDOBA', value: 19317568 },
        { name: 'CUNDINAMARCA', value: 7000 },
        // { name: 'CHOCO', value: 1392313 },
        // { name: 'HUILA', value: 1595728 },
        // { name: 'LA GUAJIRA', value: 12875255 },
        // { name: 'MAGDALENA', value: 6537334 },
        // { name: 'META', value: 3074186 },
        // { name: 'NARIÑO', value: 2885905 },
        { name: 'NORTE DE SANTANDER', value: 12000 },
        // { name: 'QUINDIO', value: 4601893 },
        // { name: 'RISARALDA', value: 1329192 },
        { name: 'SANTANDER', value: 25000 }
        // { name: 'SUCRE', value: 6646144 },
        // { name: 'TOLIMA', value: 9883360 },
        // { name: 'VALLE DEL CAUCA', value: 5379139 },
        // { name: 'ARAUCA', value: 2984926 },
        // { name: 'CASANARE', value: 6021988 },
        // { name: 'PUTUMAYO', value: 1005141 },
        // { name: 'AMAZONAS', value: 1855525 },
        // { name: 'GUAINIA', value: 2758931 },
        // { name: 'GUAVIARE', value: 1320718 },
        // { name: 'VAUPES', value: 8864590 },
        // { name: 'VICHADA', value: 2085538 },
        // { name: 'ARCHIPIELAGO DE SAN ANDRES PROVIDENCIA Y SANTA CATALINA', value: 19570261 }
    ])

    const getDepartments = () => {
      return departmentsData
    }

    const loadChar = () => {
      myChart = echarts.init(elChart.value, isDark.value ? 'dark' : 'light')
      fetch('/maps/colombia.json').then(res => res.json()).then((colombiaJson) => {
        echarts.registerMap('colombia', colombiaJson)
        const backgroundColor = isDark.value ? '#313A55' : ''
        const option = {
          backgroundColor,
          title: {
            top: 15,
            left: 15,
            text: 'Referencia Geográfica',
            subtext: 'Poblacion atendida por departamentos'
          },
          tooltip: {
            trigger: 'item',
            showDelay: 0,
            transitionDuration: 0.2,
            formatter (params) {
              var value = (params.value + '').split('.')
              if (value[0] !== 'NaN') {
                value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,')
                return params.seriesName + '<br/>' + params.name + ': ' + value
              }
              return ''
            }
          },

          visualMap: {
            type: 'piecewise',
            left: 'right',
            min: 5000,
            max: 25000,
            // inRange: {
            //   color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43']
            // },
            // text: ['High', 'Low'],
            calculable: true,
            pieces: [
              {
                value: 14000,
                label: 'CESAR',
                color: '#04BF45'
              },
              {
                value: 7000,
                label: 'CUNDINAMARCA',
                color: '#c31776'
              },
              {
                value: 12000,
                label: 'NORTE DE SANTANDER',
                color: '#A0F2F2'
              },
              {
                value: 25000,
                label: 'SANTANDER',
                color: '#F29F05'
              }
            ]
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            top: 15,
            right: 15,
            feature: {
              // dataView: { readOnly: false },
              // restore: {},
              saveAsImage: {}
            }
          },
          series: [
            {
              aspectScale: 1,
              // zoom: 2,
              // top: 70,
              // left: 'center',
              name: 'Colombia',
              type: 'map',
              roam: true,
              map: 'colombia',
              emphasis: {
                label: {
                  show: true
                }
              },
              legend: {},
              label: {
                show: false,
                fontSize: 8
              },
              // labelLine: {
              //   // length: 10
              // },
              data: getDepartments()
            }
          ]
        }

        myChart.setOption(option)

        myChart.on('click', ({ name }) => {
          console.log(name)
          // props.modelValue.department = 'CESAR'
          // setDepartment(name)
          losDepartamentos(name)
        })
      })
    }
    const losDepartamentos = (value) => {
      const tmp = props.modelValue
      if (value === 'NORTE DE SANTANDER') {
        value = 'N. DE SANTANDER'
      }
      tmp.department = value
    }

    const setSelectDepartment = (department = '') => {
      for (const dataIndex in getDepartments()) {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex
        })
      }

      if (department === 'N. DE SANTANDER') {
        department = 'NORTE DE SANTANDER'
      }
      const dataIndex = getDepartments().findIndex(a => a.name === department)
      if (dataIndex < 0) return false
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex
      })
    }

    watch(() => props.modelValue.department, (a) => {
      console.log('watch', a)
      setSelectDepartment(a)
    })

    onMounted(() => {
      loadChar()

      window.addEventListener('resize', evt => {
        myChart.resize()
      })
    })

    return { elChart }
  }
}
</script>

<style>
@media print {
  .grafica-mapa{
    overflow:hidden;
    page-break-before:always;
  }
}

</style>
