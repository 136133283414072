<template>
  <section>
    <div
      id="grafica-autorizacion"
    class="box w-full h-full flex flex-wrap items-center justify-center"
    >
      <!-- <div v-show="loading" class="rounded-md icono-carga flex justify-center items-center">
        <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
      </div> -->
      <div
      ref="elChart"
      style="height:450px; "
      class="w-full rounded-md"
      />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { apiAnalisispoliformulacion } from '../../services'
import * as echarts from 'echarts'
import { useStore } from 'vuex'
import axios from 'axios'
import { messageWarning } from '../../../../../../../libs/mensajes'
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const store = useStore()
    const loading = ref(false)
    const elChart = ref(null)
    let chartInstance = reactive({})
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const laData = ref([])
    const getData = () => {
      return laData.value
    }
    const getPoli = () => {
      return laData.value[0].Poli
    }
    const getNoPoli = () => {
      return laData.value[0].NoPoli
    }
    const setLaData = (values) => {
      laData.value = values
    }

    const initChart = () => {
      chartInstance = echarts.init(elChart.value, isDark.value ? 'dark' : 'light')
      const colorPalette = ['#04BF45', '#c31776', '#F29F05', '#12a0d7']
      const option = {
        // darkMode: true,
        backgroundColor: isDark.value ? '#313A55' : '',
        grid: {
          left: '8%',
          right: '0%',
          bottom: '2%',
          top: '18%',
          containLabel: true
        },
        title: {
          text: 'Análisis Poliformulación',
          left: 15,
          top: 15
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            myTool1: {
              show: true,
              right: 15,
              top: 10,
              title: 'Detallado',
              icon: 'image:///images/excel.png',
              onclick: () => {
                descargarExcel()
              }
            },
            saveAsImage: {}
          }
        },
        tooltip: {
          // trigger: 'item',
          formatter (a) {
            return `${a.name.split(' ').join('<br>')} <br> ${a.value} <br> ${a.percent}%`
            // return `${a.name}  <br> ${a.percent}%`
          }
        },
        legend: {
          orient: 'vertical',
          left: 15,
          bottom: 15
        },
        // backgroundColor: 'blue',
        series: [
          {
            type: 'pie',
            radius: ['20%', '45%'],
            color: colorPalette,
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 9,
              borderColor: '#fff',
              borderWidth: 2
            },
            labelLine: {
              length: 3,
              length2: 3
            },
            label: {
              fontSize: 10,
              formatter (a) {
                return `${a.data.name} \n ${a.value} \n ${a.percent}%`
              }
            },
            data: []
          }
        ]
      }
      chartInstance.setOption(option)
      loadEvents()
    }
    const setChart = () => {
      const option = {
        series: [
          {
            data: [
              { value: getPoli(), name: 'Pacientes Crónicos \nPoliformulados' },
              { value: getNoPoli(), name: 'Pacientes Crónicos \nNo Poliformulados' }
            ]
          }
        ]
      }

      chartInstance.setOption(option)
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        chartInstance.resize()
      })
    }

    const descargarExcel = () => {
      if (!props.filters.client) {
        messageWarning('Por favor seleccione un cliente para generar el reporte en excel')
        return false
      }
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report/analisis-poliformulacion-xls`
      const res = axios.getUri({ url, params: props.filters })
      window.open(res, '_blank')
    }

    /*
    * Hooks
    */
    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.client) ||
        [null, undefined, ''].includes(filter.year)
      ) return
      fetchResults(filter)
    })

    const fetchResults = (filter) => {
      loading.value = true
      apiAnalisispoliformulacion(filter).then(({ data }) => {
        setLaData(data)
        setChart()
      }).catch((err) => {
        console.log(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    onMounted(() => {
      initChart()
    })

    return {
      elChart,
      loading,
      getData,
      getPoli,
      getNoPoli
    }
  }
}
</script>

<style>
@media print {
  #grafica-autorizacion{
    overflow:hidden;
    width: 100%;
    /*page-break-before:always;*/
  }
}

</style>
