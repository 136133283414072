import { ref } from 'vue'
import { apiSelectFilter } from '../../services'

const Client = ref([])
const Service = ref([])
const Departament = ref([])
const Year = ref([])
const loading = ref(false)

const getFilters = () => {
  loading.value = true
  return apiSelectFilter().then(({ status, data }) => {
    if (status === 200) {
      Client.value = data.Client
      Service.value = data.Service
      Departament.value = data.Departament
      Year.value = data.Year
    }
  }).catch(err => {
    console.error(err.message)
  }).finally(() => {
    loading.value = false
  })
}

const setDepartment = (value) => {
  Departament.value = value
}

export {
  Client,
  Service,
  Departament,
  Year,
  loading,
  getFilters,
  setDepartment
}
