<template>
  <div class="w-full relative">
    <div v-if="!estaCargando && lengthInfoResult <= 0"  class="box w-full p-4">
      <div class="flex flex-col justify-center items-center">
        <div class="w-full sm:w-1/3 opacity-70">
          <svg data-name="Layer 1" style="height: 300px; margin: auto;" viewBox="0 0 647.63626 632.17383" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/><path d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/><path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="20" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/><path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
            <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
            <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/>
            <circle cx="433.63626" cy="105.17383" r="20" fill="#6c63ff"/><circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
          </svg>
        </div>
        <span class="text-sm sm:text-base font-bold text-red-400 my-4 normal-case text-center">No hay información para mostrar con los filtros seleccionados</span>
      </div>
    </div>
    <div v-else>
      <div v-if="estaCargando" class="box w-full h-full absolute" style="z-index: 10000;">
        <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
      </div>
      <div class="box p-5 flex flex-col justify-end">
        <div class="flex justify-end">
          <Button label="Exportar excel" icon="pi pi-file-excel" iconPos="right" @click="generarReporte" class="mb-4 p-button-success"/>
        </div>
        <DataTable
          :value="infoMipres"
          showGridlines
          responsiveLayout="scroll"
          class="p-datatable-sm"
        >
          <Column field="key" header="Item"></Column>
          <Column field="value" header="Valor"></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { apiMipres } from '../../services'
import currency from 'currency.js'
import { helper } from '../../../../../../../utils/helper'
import axios from 'axios'
import { messageWarning } from '../../../../../../../libs/mensajes'

export default {
  name: 'Mipres',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const estaCargando = ref(false)
    const infoMipres = ref([])
    const lengthInfoResult = ref(0)

    /* A la escucha de cambio Filtros */
    // watch(props.filters, (filter) => {
    //   // console.log('Entre aqui')
    //   if ([null, undefined, ''].includes(filter.client) ||
    //     [null, undefined, ''].includes(filter.year)
    //   ) return
    //   fetchResults(filter)
    // })

    /* Generar excel detallado */
    const generarReporte = () => {
      if (props.filters.client) {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report-cliente/mipres-reporte`
        const res = axios.getUri({ url, params: props.filters })
        window.open(res, '_blank')
      } else {
        messageWarning('Por favor seleccione un cliente para realizar la busqueda del paciente')
      }
    }

    /* Obtener la data */
    const fetchResults = (filter) => {
      estaCargando.value = true
      apiMipres(filter).then(({ data }) => {
        lengthInfoResult.value = data.result
        // console.log('info mipres: ', data.result[0].data)
        const result = {
            'Total Facturas': helper.formatNumber(data.result[0].data.total_facturas),
            'Total Mx': helper.formatNumber(data.result[0].data.total_mx),
            'Total Mipres': helper.formatNumber(data.result[0].data.total_mipres),
            'Total ID Principal': helper.formatNumber(data.result[0].data.total_id_principal),
            'Total ID Direccionamiento': helper.formatNumber(data.result[0].data.total_id_dirreccionamiento),
            'Total ID Rep Entrega': helper.formatNumber(data.result[0].data.total_id_rep_entrega),
            'Total ID Suministro': helper.formatNumber(data.result[0].data.total_id_suministro),
            'Total ID Factura': helper.formatNumber(data.result[0].data.total_id_factura),
            'Total Pendiente': helper.formatNumber(data.result[0].data.total_pendiente),
            'Total Vlr Total': currency(data.result[0].data.total_vlr_total, { separator: '.', precision: 0, symbol: '$' }).format(),
            'Total % Vlr Total': `${data.result[0].data.porcentaje}%`
        }

        infoMipres.value = Object.keys(result).map(key => ({ key, value: result[key] }))
        estaCargando.value = false
      }).catch((err) => {
        estaCargando.value = false
        console.log(err.message)
      }).finally(() => {
        estaCargando.value = false
      })
    }

    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.client) ||
        [null, undefined, ''].includes(filter.year)
      ) return
      fetchResults(filter)
    })

    return {
      fetchResults,
      estaCargando,
      infoMipres,
      lengthInfoResult,
      generarReporte
    }
  }
}
</script>

<style scoped>

</style>
