<template>
  <section class="box p-4">
    <div
    ref="elGrafica"
    style="height:400px;"
    class="w-full"
    />
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { reactive, ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core'
import { apiTecnicoVigilancia } from '../../services'
import currency from 'currency.js'
import axios from 'axios'
import { messageWarning } from '../../../../../../../libs/mensajes'
const { useStore } = require('vuex')
export default {
  name: 'tecnicoVigilancia',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const elGrafica = ref({})
    let chartInstance = reactive({})
    const chartData = ref({
      data: [],
      legend: {
        mediciones: 0,
        pacientes: 0
      }
    })

    onMounted(() => {
      initChart()
    })
    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.client) ||
        [null, undefined, ''].includes(filter.year)
      ) return
      fetchResults(filter)
    })
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    // #3CB2EF
    const initChart = () => {
      chartInstance = echarts.init(elGrafica.value)
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          // text: 'Tecnovigilancia (Pacientes con Desviaciones)',
          left: 'left'
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            myTool1: {
                show: true,
                right: 15,
                top: 10,
                title: 'Detallado',
                icon: 'image:///images/excel.png',
                onclick: () => {
                  descargarExcel()
                }
            }
          }
        },
        grid: {
          left: 15,
          right: 15,
          bottom: 50,
          top: 85,
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: '#3CB2EF'
            },
            label: {
              show: true,
              position: 'top',
              formatter (a) {
                return `${a.value}%`
              }
            }
          }
        ]
      }
      chartInstance.setOption(option)
      eventsChart()
    }

    const eventsChart = () => {
      window.addEventListener('resize', evt => {
        chartInstance.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        title: {
          subtext: `Total Mediciones: ${currency(chartData.value.legend.mediciones, { precision: 0, symbol: '', separator: '.' }).format()} Cantidad Pacientes: ${chartData.value.legend.pacientes}`
        },
        xAxis: {
          data: chartData.value.data.map(a => a.Clasificacion)
        },
        series: [
          {
            data: chartData.value.data.map(a => a.Porcentaje)
          }
        ]
      }

      chartInstance.setOption(option)
    }

    const descargarExcel = () => {
      if (props.filters.client) {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report/blood-glucose-report`
        const res = axios.getUri({ url, params: props.filters })
        window.open(res, '_blank')
      } else {
        messageWarning('Por favor seleccione un cliente para realizar la busqueda del paciente')
      }
    }

    const fetchResults = (filter) => {
      apiTecnicoVigilancia(filter).then(({ status, data }) => {
        chartData.value = data
        reloadChart()
      }).catch((err) => {
        console.log(err.message)
      })
    }

    return {
      elGrafica,
      isDark
    }
  }
}
</script>
