import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report`
const apiExecutionReportClient = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report-cliente`

const apiSelectFilter = () => http.get(`${api}/select-filter`)

const apiAuthorizationReason = (options) => http.get(
  `${api}/authorization-reason`,
  { params: { ...options } })

const apiTopDoctors = (options) => http.get(
  `${api}/top-doctors`,
  { params: { ...options } })

const apiTopPacientes = (options) => http.get(`${api}/top-pacientes`, { params: { ...options } })
const apiTopPacientesDetail = (options) => http.get(`${api}/top-pacientes-detail`, { params: { ...options } })

const apiTopIps = (options = {}) => http.get(`${api}/top-ips`, { params: { ...options } })
const apiResumeTotales = (options) => http.get(
  `${api}/totals`,
  { params: { ...options } })

const apiPatologia = (options = {}) => http.get(`${api}/consumption-pathology`, { params: { ...options } })

const apiTecnicoVigilancia = (queryParams = {}) => http.get(`${api}/blood-glucose-chart`, { params: { ...queryParams } })
const apiTopTecnicoVigilancia = (queryParams = {}) => http.get(`${api}/top-blood-glucose-chart`, { params: { ...queryParams } })

const apiFarmacoVigilancia = (queryParams = {}) => http.get(`${api}/interactions-chart`, { params: { ...queryParams } })
const apiTopFarmacoVigilancia = (queryParams = {}) => http.get(`${api}/topinteractions-chart`, { params: { ...queryParams } })
const apiTopFarmacoVigilanciaMayor = (queryParams = {}) => http.get(`${api}/topinteractions-mayor`, { params: { ...queryParams } })
const apiTopFarmacoVigilanciaDescription = (queryParams = {}) => http.get(`${api}/topinteractions-description`, { params: { ...queryParams } })
const apiTopPoliformulados = (options = {}) => http.get(`${api}/top-poliformulados`, { params: { ...options } })

const apiPacientesDiagnostico = (options = {}) => http.get(`${api}/pacientes-diagnostico`, { params: { ...options } })
const apiPacientesPoliformulados = (options = {}) => http.get(`${api}/pacientes-poliformulados`, { params: { ...options } })
const apiAnalisispoliformulacion = (options = {}) => http.get(`${api}/analisis-poliformulacion`, { params: { ...options } })

const apiMipres = (queryParams = {}) => http.get(`${apiExecutionReportClient}/mipres`, { params: { ...queryParams } })
const apiMipresReporte = (queryParams = {}) => http.get(`${apiExecutionReportClient}/mipres-reporte`, { params: { ...queryParams } })
// historia clinica
const apiHistoriaClinica = (queryParams = {}) => http.get(`${apiExecutionReportClient}/historia-clinica`, { params: { ...queryParams } })
const apiSolicitides = (queryParams = {}) => http.get(`${apiExecutionReportClient}/solicitudes`, { params: { ...queryParams } })
const apiSolicitidesReporte = (queryParams = {}) => http.get(`${apiExecutionReportClient}/solicitudes-reporte`, { params: { ...queryParams } })

const apiGetBodegasSaludMia = (queryParams = {}) => http.get(`${apiExecutionReportClient}/solicitudes-reporte`, { params: { ...queryParams } })
const apiHistoriaClinicaSaliudMia = (queryParams = {}) => http.get(`${apiExecutionReportClient}/historia-clinica`, { params: { ...queryParams } })

export {
  apiSelectFilter,
  apiAuthorizationReason,
  apiTopDoctors,
  apiTopIps,
  apiResumeTotales,
  apiPatologia,
  apiTecnicoVigilancia,
  apiTopTecnicoVigilancia,
  apiFarmacoVigilancia,
  apiTopFarmacoVigilancia,
  apiTopFarmacoVigilanciaMayor,
  apiTopFarmacoVigilanciaDescription,
  apiTopPoliformulados,
  apiPacientesDiagnostico,
  apiPacientesPoliformulados,
  apiAnalisispoliformulacion,
  apiTopPacientes,
  apiTopPacientesDetail,
  apiMipres,
  apiHistoriaClinica,
  apiSolicitides,
  apiSolicitidesReporte,
  apiMipresReporte,
  apiGetBodegasSaludMia,
  apiHistoriaClinicaSaliudMia
}
