<template>
  <section class="w-full border-b">
    <Divider align="left" type="dashed" id="divider-medicos">
      <b>Top 10 Pacientes Poliformulados</b>
    </Divider>
    <div style="max-height: 380px;" class="w-full">
      <DataTable
      :value="getTopData"
      :loading="loading"
      responsiveLayout="scroll"
      showGridlines
      scrollHeight="380px"
      class="p-datatable-sm"
      :scrollable="true"
      >
        <Column field="PACIENTE" header="Paciente" headerStyle="width:70%; " style="min-width: 70%; font-size:11px;" />
        <Column field="POLIFORMULADOS" header="Diagnósticos" headerStyle="width: 30%; justify-content: center" bodyStyle="justify-content:flex-end ; overflow: visible;min-width: 30%;" style="min-width: 30%; font-size:11px;" />
      </DataTable>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { apiTopPoliformulados } from '../../../../services'
export default {
  name: 'TopPoliformulados',
  props: {
    client: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const loading = ref(false)

    const topData = ref({
      data: []
    })

    const getTopData = computed(() => {
      return topData.value
    })

    onMounted(() => {
      fetchData(props.client)
    })

    const toggleLoading = () => {
      loading.value = !loading.value
    }

    const fetchData = (filter = {}) => {
      toggleLoading()
      return apiTopPoliformulados(filter).then(({ status, data }) => {
        toggleLoading()
        topData.value = data
      }).catch(err => {
        toggleLoading()
        console.error(err.message)
      })
    }

    return {
      loading,
      getTopData
    }
  }
}
</script>
