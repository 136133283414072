<template>
  <div class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
    <div class="sm:col-start-2 box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
      <h2 class="text-lg font-medium text-center text-black dark:text-white">
        {{formatterNumber(vigilancia.farmaco.interacciones)}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Cant. Interacciones
      </div>
    </div>
    <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
      <h2 class="text-lg font-medium text-center text-black dark:text-white">
        {{formatterNumber(vigilancia.farmaco.pacientes)}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Pacientes con
        interacciones
      </div>
    </div>
  </div>
</template>
<script>
import currency from 'currency.js'
import { apiFarmacoVigilancia } from '../../services'
import { ref, watch } from 'vue'
  export default {
    props: {
      filters: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const vigilancia = ref({
        tecno: {
          mediciones: 0,
          pacientes: 0
        },
        farmaco: {
          interacciones: 0,
          pacientes: 0
        }
      })

      const fetchVigilancia = (filters) => {
        apiFarmacoVigilancia(filters).then(({ data }) => {
          vigilancia.value.farmaco.pacientes = data.legend.pacientes
          vigilancia.value.farmaco.interacciones = data.legend.interacciones
        }).catch(err => {
          console.error(err.message)
        })
      }

      const formatterNumber = (value) => {
        return currency(value, { separator: '.', precision: 0, symbol: '' }).format()
      }

      watch(props.filters, (filter) => {
        if ([null, undefined, ''].includes(filter.client) ||
          [null, undefined, ''].includes(filter.year)
        ) return
        fetchVigilancia(filter)
      })
      return {
        vigilancia,
        formatterNumber
      }
    }
  }
</script>
