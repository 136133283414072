<template>
  <section class="w-full">
    <div class="w-full flex justify-between items-center my-3">
      <h1 class="font-bold text-lg capitalize">Informe Ejecución</h1>
      <div>
        <button
          type="button"
          class="bg-primary-1 text-white px-3 py-2 rounded-md "
          @click="handleDownloadPdf"
        >
          Descargar PDF
        </button>
        <!-- <button
          type="button"
          class="bg-primary-1 text-white px-3 py-2 rounded-md "
          @click="handleDownloadPdf2"
        >
          Descargar PDF
        </button> -->
        <button
          type="button"
          class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md "
          @click="handleDownloadXls"
        >
          Descargar XLS
        </button>
      </div>
    </div>
    <FiltrosEjecucion class="mt-2 mb-4 h-full" v-model="filters"/>
    <ResumenTotales class="my-2" :filters="filters"/>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-2 mt-4">
      <GraficaAutorizacion :filters="filters"/>
      <MapaColombia v-model="filters"/>
    </div>
    <div class="w-full mt-4 mb-2">
      <GraficaAcumulados :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col mt-4 mb-2">
      <TopIps :filters="filters"/>
    </div>
    <div class="w-full h-full flex flex-col my-2">
      <TopMdicos class="my-2" :filters="filters" />
    </div>
    <div class="w-full h-full flex flex-col my-2">
      <TopPacientes ref="GraficaPacientes" class="my-2" :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Tecnovigilancia</h1>
    <div class="w-full my-3">
      <CardsTecnoFarma :filters="filters" />
    </div>
    <div class="w-full my-3">
      <TecnicoVigilancia :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Farmacovigilancia</h1>
    <div class="w-full my-3">
      <CardsFarma :filters="filters" />
    </div>
    <div class="w-full my-3">
      <FarmacoVigilancia :filters="filters" />
    </div>
    <h1 class="font-bold text-lg capitalize">Auditoría Médica</h1>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-2 mt-4">
      <PacientesDiagnostico :filters="filters"/>
      <PacientesPoliformulados :filters="filters"/>
      <AnalisisPoliPacientes :filters="filters"/>
    </div>

    <div class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Gestión MIPRES</h2>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Mipres ref="CardMipres" :filters="filters" />
      </div>
    </div>

    <div class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Historia clinica</h2>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <History :filters="filters" />
      </div>
    </div>

    <div class="col-span-12 mt-5">
      <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Solicitudes</h2>
        <button
          type="button"
          class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md "
          @click="descargarExcel"
        >
          Descargar XLS
        </button>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Solicitudes ref="CardSolicitudes" :filters="filters"/>
      </div>
    </div>
  </section>
</template>

<script>
import { warning, messageWarning } from '../../../../../libs/mensajes'
import FiltrosEjecucion from './components/filtros'
import TopIps from './components/topIps'
import TopMdicos from './components/topMedicos'
import TopPacientes from './components/topPacientes'
import GraficaAutorizacion from './components/graficaAutorizacion'
import GraficaAcumulados from './components/graficaAcumulados'
import ResumenTotales from './components/resumenTotales'
import MapaColombia from './components/mapaColombia'
import { reactive } from 'vue'
import axios from 'axios'
import TecnicoVigilancia from './components/tecnicoVigilancia'
import FarmacoVigilancia from './components/farmacoVigilancia'
import CardsTecnoFarma from './components/cardsTecnoFarma'
import CardsFarma from './components/cardsFarma'
import PacientesDiagnostico from './components/AuditoriaMedica/PacientesDiagnostico'
import PacientesPoliformulados from './components/AuditoriaMedica/PacientesPoliformulados'
import AnalisisPoliPacientes from './components/AuditoriaMedica/AnalisisPoliPacientes'
import Mipres from './components/mipres'
import History from './components/historiaClinica'
import Solicitudes from './components/solicitudes'

export default {
  components: {
    FiltrosEjecucion,
    TopIps,
    TopMdicos,
    TopPacientes,
    GraficaAutorizacion,
    GraficaAcumulados,
    ResumenTotales,
    MapaColombia,
    TecnicoVigilancia,
    FarmacoVigilancia,
    CardsTecnoFarma,
    CardsFarma,
    PacientesDiagnostico,
    PacientesPoliformulados,
    AnalisisPoliPacientes,
    Mipres,
    History,
    Solicitudes
  },
  setup () {
    const filters = reactive({
      year: new Date().getFullYear(),
      month_start: '01',
      month_end: '12',
      department: null,
      service: null,
      client: null
    })

    const handleDownloadPdf = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-pdf`
      const res = axios.getUri({ url, params: filters })
      console.log('el rest que llega axios----', res)
      // if (filters.client !== null) {
      //   window.open(res, '_blank')
      // } else {
      //     warning('Seleccione un cliente')
      //   }
    }
    const handleDownloadPdf2 = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-pdf-informe-ejecutivo-envento`
      const res = axios.getUri({ url, params: filters })
      if (filters.client !== null) {
        window.open(res, '_blank')
      } else {
          warning('Seleccione un cliente')
        }
    }

    const descargarExcel = () => {
      if (filters.client) {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report-cliente/solicitudes-reporte`
        const res = axios.getUri({ url, params: filters })
        window.open(res, '_blank')
      } else {
        messageWarning('Por favor seleccione un cliente para realizar la busqueda del paciente')
      }
    }

    const handleDownloadXls = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-informe-xls`
         const res = axios.getUri({ url, params: filters })
         if (filters.client !== null) {
           window.open(res, '_blank')
         } else {
          warning('Seleccione un cliente')
        }
    }

    return {
      filters,
      handleDownloadPdf,
      handleDownloadXls,
      descargarExcel,
      handleDownloadPdf2
    }
  }
}
</script>
